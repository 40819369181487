@use "./typography";

body {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.7;
  color: #353533;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizelegibility;
  background-color: var(--web-wash);
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  // font-size: 1.6rem;
}
