@use "./functions" as *;
@use "./mixins" as *;

:root {
  --web-wash: #f0f2f5;
  --web-wash-variant: #f7f7f7;
  --color-charcoal: #344767;
  --color-white-variant: #f4f5f7;
  --text-colored-gray: #545454;
  --color-deep-sky-blue: #1ab3ff;
  --color-dodger-blue: #004cff;
  --color-dodger-blue-2: #269ed5;
  --color-dim-gray: #57585c;
  --bgcolor-form-input: #b3b3b322;
  --bgcolor-success: #8ef3c5;
  --color-success: #0fa958;
  --brand-blue: #0070c0;
  --soft-blue: #f5f8ff;
  --soft-grey: #e5ecec;
  --soft-grey-2: #bfbfbf;
  // --dark-blue: #010136;
  --color-white-variant-2: #f5f6fa;
  --color-pale-blue: #e8eef1;
  --blue-variants-gradient: linear-gradient(180deg,
      var(--color-deep-sky-blue),
      var(--color-dodger-blue));
  --blue-variant-darkdim: #001243;
  --border-color-lighter-blue: #004cff4d;
  --border-solid-light-blue: 2px solid var(--border-color-lighter-blue);
  --color-aliceblue: #004cff14;
  --medium-green: #def281;
  --soft-biege: #e9e7e4;
  --soft-voilet: #f9f6ff;
  --soft-blue-2: #d4e4f9;
  --0-10-20-box-shadow: 0px 10px 20px var(--color-aliceblue);
  --card-box-shadow: 0 1px 2px #84c5fe, 0 2px 8px rgb(#057ee6 / 10%);
  --break-point-xs: 0;
  --break-point-sm: 567px;
  --break-point-md: 768px;
  --break-point-lg: 1366px;
  --break-point-xl: 1639px;
  --container-max-width: 1140px;
  --primary-indent: 1.35rem;
  --text-main-header: 40px;
  --mq-dlg-text-main-header: 35px;
  --text-sub-header: 24px;
  --mq-dlg-text-sub-header: var(--text-md);
  --text-lg: 20px;
  --mq-text-lg: var(--text-md-variant);
  --text-md: 18px;
  --text-md-variant: 16px;
  --text-base: 14px;
  --purple: #7482eb;
  --orange: #ff7244;
  --light-blue: #001243;
  --dark-blue: #344767;
  --link-blue: #00d4ff;
  --dark-green: #00aa63;
  --yellow-like: #ffe066;
  --blue-like: #00edff;
  --beta-blue: #0070c021;
  --blog-blue: #ecf3fbcc;
}

.background-bordered-box {
  border: 1px solid var(--soft-blue-2);
  border-radius: 10px;
  background-color: var(--soft-blue);
  padding: 2rem;
  margin: 0.5rem 0;
}

.background--dashed {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23E6E9E2' stroke-width='3' stroke-dasharray='5%2c 15' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px !important;
  // overflow: hidden;
  padding: 1.5rem;
  box-shadow: rgba(3, 101, 214, 0.037) 0px 0px 0px 1px;
}

// scss-docs-start stacks
.content-col-hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  // align-self: stretch;
  margin-right: -15px;
  margin-left: -15px;

  border: 1px solid blue;
}

.content-col-vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

// scss-docs-end stacks

.content-col {
  padding: 15px;
  width: 100%;
  position: relative;
}

.content-row {
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-flow: row wrap;
}

.content-box {
  padding: 15px 15px 10px;
}

.content-col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.content-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.content-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.content-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.content-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.content-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.content-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.content-col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.content-col-12 {
  flex: 100%;
  max-width: 100%;
}

//to be removed once removed from components
//been used
.itslns-flex-container {
  display: flex;
  flex-flow: row wrap;
}

.itsolns-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1639px) {
    width: 96.5%;
  }

  @media (max-width: 992px) {
    width: 98.75%;
  }
}

.itsolns-container-2 {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1150px;
  }

  @media (min-width: 1439px) {
    width: 1300px;
  }

  @media (min-width: 1920px) {
    width: 1440px;
  }
}

.container-flex {
  display: flex;

  &>* {
    flex: 100%;
  }
}

.container {
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
}

.header1 {
  font-size: 3.2rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;

  @include maxQ(767px) {
    font-size: 2.7rem;
    line-height: 1.4;
  }
}

.header2 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;

  @include maxQ(767px) {
    font-size: 2rem;
    line-height: 1.4;
  }
}

.header3 {
  font-size: 1.8rem;
  font-weight: 700;

  @include maxQ(767px) {
    font-size: 1.4rem;
  }
}

.text-sub-header {
  font-size: var(--text-sub-header);
}

.header4 {
  font-size: 1.4rem;
  font-weight: 700;

  @include maxQ(767px) {
    font-size: 1.2rem;
  }
}

.header5 {
  font-size: 1.125rem;
  font-weight: 700;
}

.paragraph {
  font-size: 1rem;
}

.header-large {
  font-size: var(--text-main-header);
  font-family: "Open Sans", "century gothic";
  line-height: 1.5;
}

.small-text {
  font-size: 0.895rem;
}

.medium-text {
  font-size: 1.15rem;
}

.medium-text-variant {
  font-size: 1.25rem;
}

.medium-text-variant-end {
  font-size: 1.6rem;
}

.large-text-base {
  font-size: 1.875rem;
}

.large-text-base-1 {
  font-size: 2rem;
}

.large-text-base-2 {
  font-size: 2.533rem;
}

.fw-900 {
  font-weight: 900;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-450 {
  font-weight: 450;
}

.fw-400 {
  font-weight: 400;
}

.padding-smx {
  padding: 0.25rem;
}

.padding-smx-variant {
  padding: 0.35rem;
}

.padding-sm {
  padding: 0.635rem;
}

.padding-md {
  padding: 0.85rem;
}

.padding-1 {
  padding: 1rem;
}

.padding-2-variant {
  padding: 2.5rem;
}

.padding-2 {
  padding: 2rem;
}

.pdn-lft-2 {
  padding-left: 2rem;
}

.pdn-lft-3 {
  padding-left: 3rem;

  @media (max-width: 102.4375em) {
    padding-left: 1.25rem;
  }
}

.pdn-lft-4 {
  padding-left: 4rem;
}

.pdn-lft-6 {
  padding-left: 6rem;

  @media (max-width: 512px) {
    padding-left: 2rem;
  }
}

.pdn-rgt-1 {
  padding-right: 1rem;
}

.pdn-rgt-2 {
  padding-right: 2rem;
}

.pdn-rgt-6 {
  padding-right: 6rem;
}

.pdn-rgt-4 {
  padding-right: 4rem;
}

.padding-inline-sm {
  padding-left: 0.35rem;
  padding-right: 0.35rem;
}

.padding-inline-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-inline-1-md {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.padding-inline-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.padding-inline-3 {
  padding-left: 3rem;
  padding-right: 3rem;

  @media (max-width: 102.4375em) {
    padding-left: 1.55rem;
    padding-right: 1.55rem;
  }
}

.padding-inline-md {
  padding-left: 4rem;
  padding-right: 4rem;

  @media (max-width: 60em) {
    padding-left: 0.65rem;
    padding-right: 0.65rem;
  }

  @media (max-width: 102.4375em) {
    padding-left: 1.55rem;
    padding-right: 1.55rem;
  }
}

.padding-block-start-1 {
  padding-top: 1rem;
}

.padding-block-end-2 {
  padding-bottom: 2rem;
}

.padding-block-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padding-block-1-sm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.padding-block-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding-block-1-variant {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.padding-block-1-variant-md {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.padding-block {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padding-block-md {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.padding-block-lg {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: 102.4375em) {
    padding-top: 1.55rem;
    padding-bottom: 1.55rem;
  }
}

.padding-block-smxx {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.padding-block-smx {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}

.padding-block-xxsmx {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-top-2-variant {
  padding-top: 3.5rem;
}

.padding-top-3 {
  padding-top: 4rem;
}

.padding-top-3-variant {
  padding-top: 4.25rem;
}

.padding-top-3-variant-1 {
  padding-top: 5rem;
}

.padding-top-4 {
  padding-top: 6rem;
}

.padding-top-5 {
  padding-top: 8rem;
}

.padding-btm-3 {
  padding-bottom: 3rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.mg-block-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mg-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mg-block-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mg-block-lg {
  margin-top: 10rem;
  margin-bottom: 10rem;

  @media (max-device-width: 1280px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.mg-block-lg-varaint {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mg-block-end-1 {
  margin-bottom: 2rem;
}

.mg-block-end-sm {
  margin-bottom: 1rem;
}

.mg-block-end-2 {
  margin-bottom: 3rem;
}

.mg-block-end-smallx {
  margin-bottom: 0.5rem;
}

.mg-top-1 {
  margin-top: 4rem;
}

.mg-top-2 {
  margin-top: 2rem;
}

.mg-top-3 {
  margin-top: 1rem;
}

.mg-top-sm {
  margin-top: 0.65rem;
}

//smallest value for a margin-left
.mg-lft-smx {
  margin-left: 0.35rem;
}

.mg-lft-sm {
  margin-left: 0.5rem;
}

.mg-lft-sm-1 {
  margin-left: 0.75rem;
}

.mg-rght-sm {
  margin-right: 0.5rem;
}

.mg-rght-smx {
  margin-right: 0.35rem;
}

.mg-rght-1 {
  margin-right: 1rem;
}

.mg-rght-1-md {
  margin-right: 1.5rem;
}

.mg-rght-2 {
  margin-right: 2rem;
}

.mg-rght-3 {
  margin-right: 3rem;
}

.mg-rght-4 {
  margin-right: 4rem;
}

.mg-lft-1 {
  margin-left: 1rem;
}

//margin-left of 2rem
.mg-lft-2 {
  margin-left: 2rem;

  @include maxQ(768px) {
    margin-left: 0px;
  }
}

.mg-lft-5 {
  margin-left: 5rem;
}

.mr-lft-10 {
  margin-left: 10rem;
}

.mr-lft-auto {
  margin-left: auto;
}

.mr-right-auto {
  margin-right: auto;
}

.mg-inline-auto {
  margin-left: auto;
  margin-right: auto;
}

.mg-inline-smx {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mg-inline-sm {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mg-inline-md {
  margin-left: 1.15rem;
  margin-right: 1.15rem;
}

.negative-mg-block-end-1 {
  margin-bottom: -1rem;
}

.negative-mg-block-start-1 {
  margin-top: -1rem;
}

.negative-mg-block-start-2 {
  margin-top: -2.5rem;
}

.negative-mg-block-start-3 {
  margin-top: -3rem;
}

.negative-mg-block-start-4 {
  margin-top: -3.75rem;
}

.word-wrap {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.border-1-all-lightblue {
  border: 1px solid #cde2fb;
}

.blueColor {
  color: color(blue);
}

.warning__text {
  color: #ff0000;
}

.primary-green {
  color: #00ca4e;
}

.secondary-color {
  color: var(--color-deep-sky-blue);
}

.success__text {
  color: color(green);
}

.dark-slate-gray {
  color: #2b394f;
}

.font__primaryDark {
  color: #000f3d;
}

.border-rad-1 {
  border-radius: 1rem;
}

.text-color-blue-variant {
  color: #051f64;
}

.bg-white-variant {
  background-color: #fbfcfe;
}

.bg-white-variant-2 {
  background-color: #fafbfd;
}

.bg-white-variant-3 {
  background-color: #f9fcfc;
}

.text-color-white {
  color: #fff;
}

.text-justify {
  text-align: justify;
}

.text-color-dim-gray {
  color: var(--color-dim-gray);
}

.text-indent {
  text-indent: var(--primary-indent);
}

.bg-light-green {
  background-color: lighten(#00ca4e, 55%);
}

.bg-greyish-light {
  background-color: #455a641a;
}

.bg-greyish-light-2 {
  background-color: #caeaf92a;
}

.bg-transparent {
  background-color: transparent;
}

.bg-blue {
  background-color: color(blue);
  &:hover {
    opacity: 0.8;
  }
  &--disabled {
    opacity: 0.5;
    cursor: none;
    &:hover {
      opacity: 0.5;
    }
  }
}

.bg-blue-2 {
  background-color: #004cff;
}

.primary-blue-color-variant {
  color: var(--color-deep-sky-blue);
}

.bg-lightBlue-md-variant-1 {
  background-color: #004cff0d;
}

.bg-lightBlue-md-variant-end {
  background-color: #c0d2fc0d;
}

.bg-lightBlue-md {
  background-color: #004cff33;
}

.bg-warning {
  background-color: #ff0000;
}

.blue-variants-linear-gradient-bg {
  background-image: var(--blue-variants-gradient);
}

.bg-red-light {
  background-color: hsla(0, 100%, 50%, 0.102);
}

.bg-color-white {
  background-color: #fff;
}

.bg-gray-1 {
  background-color: lighten(#85979f, 30%);
}

.overlay-bg-bluish {
  background-image: linear-gradient(45deg, #004cff7d, #004cff7b);
  filter: contrast(70%);
  z-index: 2;
}

.all-border-light-gray {
  border: 1px solid #66666615;
}

.font-wgt-600 {
  font-weight: 500;
}

.ltr-space-smx {
  letter-spacing: 0.5px;
}

.border-rad-50 {
  border-radius: 50%;
}

.overflow-hidden {
  overflow: hidden;
}

.border-right-solid-darkblue {
  border-right: 1px solid #051f64;
}

.border-solid-blue-2 {
  border: 2px solid color(blue);
}

.border-solid-blue-1 {
  border: 1px solid color(blue);
}

.border-solid-light-blue {
  border: 1px solid #cde2fb;
}

.border-dashed-light-blue {
  border: 1px solid #cde2fb;
}

.border-solid-green-color {
  border: 2px solid #00ca4e;
}

.border-solid-red {
  border: 2px solid #ff0000;
}

.border-bottom-light-blue {
  border-bottom: 1px solid #cde2fb;
}

.border-rad-10px {
  border-radius: 10px;
}

.border-rad-20px {
  border-radius: 20px;
}

.border-rad-30px {
  border-radius: 30px;
}

.border-rad-5px {
  border-radius: 5px;
}

.check-border {
  border: 1px solid red;
}

.primary-box-shadow {
  @include primaryShadow;
}

.primary-box-shadow-1 {
  @include primaryShadow-2;
}

.primary-box-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.primary-box-shadow-3 {
  @include primaryShadow-3;
}

.primary-box-shadow-4 {
  box-shadow: 0px 3px 6px #455a6414;
}

.card-shadow {
  box-shadow: var(--card-box-shadow);
}

.primary-box-shadow-5 {
  box-shadow: rgba(122, 167, 244, 0.075) 0px 1px 2px,
    rgba(142, 180, 245, 0.075) 0px 0px 1px 2px;
}

.y-only-box-shadow {
  @include y-shadow-line;
}

.y-only-box-shadow-2 {
  @include y-faded-shadow;
}

.text-gray-1 {
  color: #85979f;
}

.text-gray-variant {
  color: var(--text-colored-gray);
}

.primary-dark-blue {
  color: #001243;
}

.border-0 {
  border: 0;
}

.border-solid-transparent-2 {
  border: 2px solid transparent;
}

.outline-none {
  outline: none;
}

.text-color-black {
  color: #001243;
}

.text-color-yellow {
  color: #ffcc00;
}

.primary-blue {
  color: var(--brand-blue);
}

.dark-blue-text-stroke {
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--blue-variant-darkdim);
}

.white-text-stroke {
  -webkit-text-fill-color: var(--blue-variant-darkdim);
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
}

.primary-blue-2 {
  color: #004cff;
}

.flex-center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer-cursor {
  cursor: pointer;
}

.border-solid-blue {
  border: 1px solid #051f64;
}

.bg-light-yellow {
  background-color: #ffcc001a;
}

.rotate-180deg {
  transform: rotate(180deg);
}

.btn {
  border: 0;
  background-color: transparent;
  outline: none;
}

.lg-btn-width {
  width: 11rem;
}

.border-block-blue-varaint {
  border-top: var(--border-solid-light-blue);
  border-bottom: var(--border-solid-light-blue);
}

.w-100vw {
  width: 100vw;
}

.w-60vw {
  width: 60vw;
}

.w-70vw {
  width: 70vw;
}

.w-80vw {
  width: 80vw;
}

.w-50vw {
  width: 50vw;
}

.h-100vh {
  height: 100vh;
}

.w-50 {
  width: 50%;

  @include maxQ(767px) {
    width: 100%;
  }
}

.w-100 {
  width: 100%;
}

.w-30 {
  width: 30%;

  @include maxQ(767px) {
    width: 100%;
  }
}

.w-70 {
  width: 70%;

  @include maxQ(767px) {
    width: 100%;
  }
}

.w-25 {
  width: 25%;

  @include maxQ(767px) {
    width: 100%;
  }
}

//kindly rename and change in elements used
.w-30px {
  width: 25px;

  @include maxQ(767px) {
    width: 100%;
  }
}

.h-25px {
  height: 25px;
}

.w-20px {
  width: 20px;
}

.h-20px {
  height: 20px;
}

.h-15px {
  height: 15px;
}

.w-15px {
  width: 15px;
}

.h-13px {
  height: 13px;
}

.w-13px {
  width: 13px;
}

.w-70px {
  width: 70px;
}

.h-70px {
  height: 70px;
}

.w-60px {
  width: 60px;
}

.h-60px {
  height: 60px;
}

.w-40px {
  width: 40px;
}

.h-40px {
  height: 40px;
}

.w-50px {
  width: 50px;
}

.h-50px {
  height: 50px;
}

.h-50 {
  height: 50px;
}

.fixed-width-100 {
  width: 100px;
  max-width: 100px;
}

.h-100 {
  height: 100%;
}

.fixed-width-350 {
  width: 350px;
}

.fixed-width-300 {
  width: 300px;
}

.fixed-wt-170 {
  width: 180px;
}

.fixed-wt-120 {
  width: 120px;
}

.max-height-1 {
  max-height: 10rem;
}

.max-height-3 {
  max-height: 5rem;
}

.max-width-1 {
  max-width: 10rem;
}

.max-width-1-variant {
  max-width: 14.25rem;
}

.fixed-width-1 {
  width: 10rem;
}

.max-width-2 {
  max-width: 20rem;
}

.max-height-1-lg-variant {
  max-height: 17.5rem;
}

.max-width-2-variant-1 {
  max-width: 22.5rem;
}

.max-width-2-variant-2 {
  max-width: 25rem;
}

.max-width-2-variant-3 {
  max-width: 27.5rem;
}

.max-width-3 {
  max-width: 30rem;
}

.max-width-3-variant-1 {
  max-width: 32.5rem;
}

.max-width-3-variant-2 {
  max-width: 35rem;
}

.max-width-3-variant-3 {
  max-width: 36.15rem;
}

.max-width-4 {
  max-width: 40rem;
}

.max-width-4-variant {
  max-width: 45rem;
}

.max-width-5 {
  max-width: 50rem;
}

.max-width-6 {
  max-width: 60rem;
}

.min-width-sm {
  min-width: 3.5rem;
}

.min-width-sm-variant {
  min-width: 5rem;
}

.min-width-4 {
  min-width: 40rem;
}

.min-width-3 {
  min-width: 30rem;
}

.min-width-120 {
  min-width: 8rem;
}

.max-height-2-varaint-2 {
  max-height: 28.57rem;
}

.resize-none {
  resize: none;
}

.image-cover {
  object-fit: cover;
}

.image-contain {
  object-fit: contain;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.left-1 {
  left: 1rem;
}

.top-1 {
  top: 1rem;
}

.right-1 {
  right: 1rem;
}

.right-5 {
  right: 5rem;
}

.right-2 {
  right: 2rem;
}

.right-10px {
  right: -50px;
}

.stack-behind {
  z-index: -1;
}

.stack-infront {
  z-index: 1;
}

.stack-front {
  z-index: 99;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-50 {
  flex: 50%;
}

.flex-30 {
  flex: 30%;
}

.flex-25 {
  flex: 25%;
}

.flex-40 {
  flex: 40%;
}

.flex-60 {
  flex: 60%;
}

.flex-70 {
  flex: 70%;
}

.flex-600 {
  flex: 600px;
}

.flex-500 {
  flex: 500px;
}

.flex-400 {
  flex: 400px;
}

.flex-grow-sm {
  flex-grow: 25rem;
}

.flex__between {
  display: flex;
  justify-content: space-between;
}

.algn-ctr {
  align-items: center;
}

.algn-self-ctr {
  align-self: center;
}

.algn-start {
  align-items: flex-start;
}

//rename
.algn-base {
  align-items: flex-end;
}

.algn-flex-end {
  align-items: flex-end;
}

.algn-slf-end {
  align-self: flex-end;
}

.js-ctr {
  justify-content: center;
}

.js-space-btw {
  justify-content: space-between;
}

.js-space-evenly {
  justify-content: space-evenly;
}

.js-end {
  justify-content: flex-end;
}

.js-start {
  justify-content: flex-start;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.inline-flex-container {
  display: inline-flex;
}

.display-inline-block {
  display: inline-block;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex__row {
  display: flex;
  flex-direction: row;

  @include maxQ(767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.flex-wrap {
  @media (max-width: 60em) {
    flex-wrap: wrap;
  }
}

.flex-gap-1 {
  gap: 1rem;
}

.flex-gap-2 {
  gap: 2rem;
}

.flex__end {
  display: flex;
  justify-content: flex-end;
}

.text-align-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.flex-same-size {
  flex: 1;
}

/***
media query classes adjustments for screen size

***/

@media (max-width: 71.42rem) {
  .md-screen-display-none {
    display: none;
  }

  .md-screen-display-block {
    display: block;
  }

  .md-screen-pos-absolute {
    position: absolute;
  }

  .md-screen-w100vh {
    width: 100vh;
  }

  .md-screen-h100vh {
    height: 100vh;
  }

  .md-screen-flex-column {
    display: flex;
    flex-direction: column;
  }

  .md-screen-center-1 {
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate(-25%, -25%);
  }

  .md-screen-center-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .md-screen-all-padding-unset {
    padding: unset;
  }

  .md-screen-pos-relative {
    position: relative;
  }

  .md-screen-js-center {
    justify-content: center;
  }
}

/***
media query classes adjustments for screen size

***/

.hovered:hover,
.hovered:focus {
  background-image: var(--blue-variants-gradient);
}

//media adjustments for helper classess
@media (max-width: 1124px) {
  .header-large {
    font-size: var(--text-sub-header);
  }

  .text-sub-header {
    font-size: var(--text-md-variant);
  }
}

//heading
.card-heading {
  font-size: 1.45rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: var(--color-charcoal);
}
.error {
  font-size: 0.75rem;
  color: red;
  font-style: italic;
}