@use "../../index" as *;
@use "./normalize";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  // font-size: 62.5%;
}

a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
}

ul,
li {
  list-style-type: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

::-webkit-scrollbar {
  width: 5px;
}

input {
  line-height: normal;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  border-radius: 10px;
  background-color: lighten($color: #0070c0, $amount: 50%);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: var(--brand-blue);
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*Chrome, Safari, Edge, Opera*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**Firefox fix for input number */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button,
select {
  text-transform: none;
}