@font-face {
  font-family: cera_pro_light;
  src: url("../../../assets/fonts/Cera_Pro_Light.otf");
}

@font-face {
  font-family: cera_pro_black;
  src: url("../../../assets/fonts/Cera_Pro_Black.otf");
}

@font-face {
  font-family: cera_pro_bold;
  src: url("../../../assets/fonts/Cera_Pro_Bold.otf");
}

@font-face {
  font-family: cera_pro_medium;
  src: url("../../../assets/fonts/Cera_Pro_Medium.otf");
}

@font-face {
  font-family: muli;
  src: url("../../../assets/fonts/Muli-Regular.ttf");
}