@mixin maxQ($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin minQ($arg) {
  @media screen and (min-width: $arg) {
    @content;
  }
}

@mixin maxHeight($arg) {
  @media screen and (max-height: $arg) {
    @content;
  }
}

@mixin minHeight($arg) {
  @media screen and (min-height: $arg) {
    @content;
  }
}

@mixin maxQH($arg1, $arg2) {
  @media screen and (max-width: $arg1) and (max-height: $arg2) {
    @content;
  }
}

@mixin maxQminH($arg1, $arg2) {
  @media screen and (max-width: $arg1) and (min-height: $arg2) {
    @content;
  }
}

@mixin minQmaxQ($arg1, $arg2) {
  @media screen and (min-width: $arg1) and (max-width: $arg2) {
    @content;
  }
}

@mixin primaryShadow {
  box-shadow: 0px 5px 40px #004cff1a;
}

@mixin primaryShadow-2 {
  box-shadow: 0px 10px 20px #004cff33;
}
@mixin primaryShadow-3 {
  box-shadow: 0 2px 6px 0 rgb(51 51 51 / 10%);
}
@mixin primaryShadow-3-variant {
  box-shadow: 0 2px 6px 0 rgb(51 51 51 / 10%);
}

@mixin y-shadow-line {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

@mixin y-faded-shadow {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 5px -10px;
}

@mixin width($arg) {
  width: $arg;
}

//mixin for defining a flexbox layout
@mixin dflex($align: stretch, $justify: flex-start, $direction: row, $gap: 0) {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  gap: $gap;
}

@mixin triangle($direction, $size, $color) {
  width: 0;
  height: 0;

  @if $direction == "up" {
    border-right: $size solid transparent;
    border-bottom: $size * 1.732 solid $color;
    border-left: $size solid transparent;
  } @else if $direction == "down" {
    border-top: $size * 1.732 solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == "left" {
    border-top: $size solid transparent;
    border-right: $size * 1.732 solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == "right" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size * 1.732 solid $color;
  }
}
