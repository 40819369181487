.alert__wrapper {
  position: fixed;
  top: 95px;
  right: 10px;
  min-width: 250px;
  z-index: 100;
}

.alert {
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 250px;
  transition: 0.3s;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
  animation: alertSlideIn 0.4s ease-in-out;

  @keyframes alertSlideIn {
    0% {
      margin-left: 130%;
    }
    100% {
      margin: 0;
    }
  }

  @keyframes alertSlideOut {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: 130%;
    }
  }

  &.success {
    background-color: rgb(96, 167, 96);
    color: white;

    .alert__bar {
      background-color: green;
    }
  }

  &.error {
    background-color: rgb(199, 63, 63);
    color: white;

    .alert__bar {
      background-color: red;
    }
  }

  &.warning {
    background-color: orange;
    color: white;

    .alert__bar {
      background-color: darken(orange, 20%);
    }
  }

  &.exit {
    animation: alertSlideOut 0.4s ease-in-out forwards;
  }

  .alert__icon {
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 10px;
  }

  .alert__message {
    padding: 14px 10px 10px;
  }

  .alert__bar {
    height: 5px;
    background-color: green;
  }
}
