@use "styles" as *;

.loadingPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color(white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  @include maxQ(767px) {
    transform: scale(0.8);
  }

  .loadingPage__container {
    width: 316px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    overflow: hidden;
    flex-direction: column;
  }

  .loadingPage__letterDiv {
    display: flex;
    align-items: center;
    position: relative;
    // overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: -2px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: color(blue, light);
      animation: animateLoadingBall 3s infinite;
    }
  }

  .loadingPage__line {
    position: relative;
    width: 80%;
    height: 10px;
    border-radius: 5px;
    bottom: -10px;
    left: 0;
    background-color: color(blue, light);
    animation: animateLoadingLine 1s forwards;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 70px;
      background-color: color(white);
      left: -10px;
      top: 0;
      animation: animateWhiteBall 3s infinite;
      border-radius: 5px;
    }
  }

  @keyframes animateLoadingBall {
    0% {
      transform: translateX(-20px) translateY(0);
    }

    30% {
      transform: translateX(250px) translateY(0);
    }

    50% {
      transform: translateX(0) translateY(0);
    }

    65% {
      transform: translateX(0) translateY(-50px);
    }

    80% {
      transform: translateX(0) translateY(0);
    }

    100% {
      transform: translateX(0) translateY(0);
    }
  }

  @keyframes animateLoadingLine {
    0% {
      transform: translateX(-100%) rotatey(360deg);
    }

    70% {
      transform: translateX(20%);
    }

    100% {
      //   background-image: linear-gradient(to right, color(blue, light), white);
      transform: translateX(0) rotatey(360deg);
    }
  }

  .loadingPage__letter {
    font-size: 3rem;
    font-family: muli;
    transform-origin: bottom;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      margin-right: 10px;
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      animation-delay: 0.5s;
    }

    &:nth-child(5) {
      animation-delay: 0.6s;
    }

    &:nth-child(6) {
      animation-delay: 0.7s;
    }

    &:nth-child(7) {
      animation-delay: 0.8s;
    }

    &:nth-child(8) {
      animation-delay: 0.9s;
    }

    &:nth-child(9) {
      animation-delay: 1s;
    }

    &:nth-child(10) {
      animation-delay: 1.1s;
    }

    &:nth-child(11) {
      animation-delay: 1.2s;
    }

    animation: animateLoadingText 3s infinite;
  }

  @keyframes animateLoadingText {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes animateWhiteBall {
    0% {
      left: -11%;
    }

    45% {
      left: calc(100% - 20px);
    }

    100% {
      left: -11%;
    }
  }
}

.loadingPage__cube {
  position: absolute;
  height: calc(100vh - 150px);
  perspective: 800px;
  perspective-origin: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loadingPage__cubeContainer {
    // transform: scale(0.7);
    transform-style: preserve-3d;
    width: 40px;
    height: 40px;
    position: relative;
    animation: animateLoaderCube 2s ease-in-out infinite;
  }

  @keyframes animateLoaderCube {
    0% {
      transform: scale(0.6) rotateX(0) rotateY(0);
    }

    100% {
      transform: scale(0.6) rotateX(360deg) rotateY(360deg);
    }
  }

  .loadingPage__side {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: color(blue, light);
    backface-visibility: hidden;

    &:nth-child(1) {
      transform: translateZ(20px);
    }

    &:nth-child(2) {
      transform: rotateY(180deg) translateZ(20px);
    }

    &:nth-child(3) {
      transform: rotateY(90deg) translateZ(20px);
    }

    &:nth-child(4) {
      transform: rotateY(-90deg) translateZ(20px);
    }

    &:nth-child(5) {
      transform: rotateX(90deg) translateZ(20px);
    }

    &:nth-child(6) {
      transform: rotateX(-90deg) translateZ(20px);
    }
  }
}
